@use "../../sass/vars";
@use "../../sass/utils";

header {
  position: relative;

  ._bg {
    @include utils.aspect-ratio(1440, 742);
    background-image: url('./img/hero-left-mb.png');
    z-index: 0;
  }

  ._contents {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    z-index: 1;

    ._text {
      padding-top: 45%;
    }

    ._separator {
      margin: 20px 0;
      height: 1px;
      width: 100%;
      background: vars.$color-white;
    }
  }

  ._cols {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: vars.$breakpoint-sm) {
  header {
    ._contents {
      ._text {
        padding-top: 47%;
      }
    }
  }
}

@media screen and (min-width: vars.$breakpoint-md) {
  header {
    ._bg {
      display: none;
    }

    ._contents {
      ._text {
        padding-top: 50px;
      }
    }
  }
}

@media screen and (min-width: vars.$breakpoint-lg) {
  header {
    min-height: 52vw;

    ._bg {
      display: block;
    }

    ._contents {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      ._text {
        max-width: 60%;
        padding-top: 25px;
      }
    }

    ._cols {
      flex-direction: row;

      > div {
        flex-basis: 50%;
      }
    }
  }
}

@media screen and (min-width: vars.$breakpoint-xl) {
  header {
    ._contents {
      ._separator {
        margin: 30px 0;
      }
    }
  }
}

@media screen and (min-width: vars.$breakpoint-xxl) {
  header {
    ._contents {
      ._text {
        padding-top: 90px;
      }
    }
  }
}

@media screen and (min-width: vars.$breakpoint-3xl) {
  header {
    ._contents {
      ._text {
        margin-right: 200px;
      }

      ._separator {
        margin: 90px 0;
      }
    }
  }
}

@media screen and (min-width: vars.$breakpoint-4xl) {
  header {
    ._contents {
      ._text {
        margin-right: 300px;
      }
    }
  }
}