// colors
$color-glaucous: #5A71AD;
$color-midnight-green: #024B4E;
$color-ghoust-white: #F2F6FF;
$color-white: #fff;
$color-black: #000;

// fonts
$font-primary: "Interstate", "Arial", sans-serif;

// breakpoints
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1366px;
$breakpoint-xl: 1600px;
$breakpoint-xxl: 1920px;
$breakpoint-3xl: 2560px;
$breakpoint-4xl: 3440px;