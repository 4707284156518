@use "../../sass/vars";

footer {
  padding: 70px 0 50px;
  background: vars.$color-ghoust-white;

  p {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  ._logo {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
  }

  ._cols {
    padding: 0 20px;
  }

  ._box {
    margin-bottom: 32px;
    padding: 16px;
    border: 1px solid vars.$color-black;

    p {
      margin-bottom: 0;
    }
  }

  ._menu {
    margin-bottom: 16px;
    padding: 0;
    list-style-type: none;

    li {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem;
    }
  }
}

@media screen and (min-width: vars.$breakpoint-lg) {
  footer {
    ._logo {
      max-width: 400px;
    }

    ._cols {
      display: flex;
      padding: 0;

      > div {
        flex-basis: 50%;
        padding: 0 16px;
      }
    }

    ._menu {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      overflow: hidden;

      li {
        margin-left: -20px;
        margin-right: 40px;

        &:before {
          display: inline-block;
          margin-right: 16px;
          content: '|';

        }
      }
    }
  }
}

@media screen and (min-width: vars.$breakpoint-xxl) {
  footer {
    ._cols {
      > div {
        padding: 0 32px;
      }
    }
  }
}