@use "../../sass/vars";

.LocationsSelector {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (min-width: vars.$breakpoint-sm) {
  .LocationsSelector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
}

@media screen and (min-width: vars.$breakpoint-md) {
  .LocationsSelector {
    gap: 20px;
  }
}

@media screen and (min-width: vars.$breakpoint-lg) {
  .LocationsSelector {
    grid-template-columns: repeat(3, 1fr);
  }
}