@use "vars";

html, body * {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: vars.$font-primary;
  font-weight: 400;
  font-size: 16px;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: vars.$color-midnight-green;
}

h1 {
  margin: 0 0 40px;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.75rem;
  color: vars.$color-midnight-green;
}

h2, .h2 {
  margin: 0 0 30px;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.875rem;
  color: vars.$color-midnight-green;
}

h3, .h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.875rem;
}

p {
  margin: 0 0 32px;
  font-size: 1rem;
}

a {
  color: vars.$color-midnight-green;
}

ul {
  margin: 0;

  li {
    font-size: 1.3rem;
    line-height: 2.125rem;
  }
}

img {
  max-width: 100%;
}

@media screen and (min-width: vars.$breakpoint-xl) {
  html, body {
    font-size: 20px;
  }
}

@media screen and (min-width: vars.$breakpoint-xxl) {
  html, body {
    font-size: 24px;
  }
}

@media screen and (min-width: vars.$breakpoint-3xl){
  html, body {
    font-size: 28px;
  }
}