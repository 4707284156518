
@mixin aspect-ratio($width, $height) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: calc(($height / $width) * 100%);
  background: {
    repeat: no-repeat;
    size: cover;
  };
}