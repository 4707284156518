@use "../../sass/vars";

.Logo {
  margin: 15px 0 0;
  width: 280px;
}

@media screen and (min-width: vars.$breakpoint-lg) {
  .Logo {
    margin: {
      left: 30px;
      top: 30px;
    }
  }
}