@use "../../sass/vars";

.Location {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 80px;
  font-size: 1.5rem;
  font-weight: 700;
  color: vars.$color-white;
  background: vars.$color-glaucous;
  cursor: pointer;

  img {
    height: 100%;
    max-width: 80px;
    object-fit: cover;
  }

  span {
    display: block;
    flex-grow: 1;
    text-align: center;
  }

  &.-coming-soon {
    opacity: .5;
    cursor: default;
  }
}

@media screen and (min-width: vars.$breakpoint-sm) {
  .Location {
    width: 100%;
  }
}

@media screen and (min-width: vars.$breakpoint-lg) {
  .Location {
    height: 60px;
    font-size: 1.25rem;

    img {
      max-width: 60px;
    }
  }
}

@media screen and (min-width: vars.$breakpoint-xxl) {
  .Location {
    height: 80px;

    img {
      max-width: 80px;
    }
  }
}
